import React from 'react'
import ReactDOM from 'react-dom'
import { PdfViewer } from './PdfViewer'

export function renderPdfs(root: HTMLElement) {
  renderProseMirrorPdfs(root)
  renderCKEditorPdfs(root)
}

export function renderProseMirrorPdf(pdfEl: HTMLElement) {
  const thumbnailUrl = pdfEl.hasAttribute('data-thumbnail-url')
    ? pdfEl.getAttribute('data-thumbnail-url')
    : undefined
  const url = pdfEl.getAttribute('src')

  ReactDOM.render(
    <PdfViewer
      style={{
        height: Number.parseInt(pdfEl.style.height, 10),
      }}
      thumbnailUrl={thumbnailUrl}
      url={url}
      width={Number.parseInt(pdfEl.style.width, 10)}
    />,
    pdfEl,
  )
}

export function renderProseMirrorPdfs(root: HTMLElement) {
  const pdfEls = root.querySelectorAll<HTMLElement>('rw-pdf')
  for (const pdfEl of pdfEls) {
    renderProseMirrorPdf(pdfEl)
  }
}

export function renderCkeditorPdf(figureEl: HTMLElement) {
  const oembedEl = figureEl.querySelector('oembed')

  const aspectRatio: React.CSSProperties['aspectRatio'] =
    ('computedStyleMap' in oembedEl
      ? oembedEl.computedStyleMap().get('aspect-ratio')?.toString()
      : window
          .getComputedStyle(oembedEl)
          .getPropertyValue('aspect-ratio')
          ?.trim()) || '8.5 / 11'

  const showThumbnail = oembedEl.classList.contains('ck-pdf-show-thumbnail')
  const url = oembedEl.getAttribute('url')

  const thumbnailUrl = showThumbnail ? `${url}/thumbnail.png` : undefined

  ReactDOM.render(
    <PdfViewer
      style={{ aspectRatio }}
      thumbnailUrl={thumbnailUrl}
      url={url}
      width='100%'
    />,
    figureEl,
  )
}

export function renderCKEditorPdfs(root: HTMLElement) {
  const figureEls = root.querySelectorAll<HTMLElement>('figure.pdf')
  for (const figureEl of figureEls) {
    renderCkeditorPdf(figureEl)
  }
}
