import type { Translations } from '@blissbook/lib/types'
import { useMemo } from 'react'

export type LanguageCode = string

export type Language = {
  code: LanguageCode
  isoName: string
  nativeName: string
  translations?: Translations
}

// Google Translate API: https://cloud.google.com/translate/docs/languages
export const languages = [
  {
    code: 'af',
    isoName: 'Afrikaans',
    nativeName: 'Afrikaans',
  },
  {
    code: 'sq',
    isoName: 'Albanian',
    nativeName: 'Shqip',
  },
  {
    code: 'am',
    isoName: 'Amharic',
    nativeName: 'አማርኛ',
  },
  {
    code: 'ar',
    isoName: 'Arabic',
    nativeName: 'اللغة العربية',
  },
  {
    code: 'hy',
    isoName: 'Armenian',
    nativeName: 'Հայերեն',
  },
  {
    code: 'az',
    isoName: 'Azerbaijani',
    nativeName: 'azərbaycan dili',
  },
  {
    code: 'eu',
    isoName: 'Basque',
    nativeName: 'euskara',
  },
  {
    code: 'be',
    isoName: 'Belarusian',
    nativeName: 'беларуская мова',
  },
  {
    code: 'bn',
    isoName: 'Bengali',
    nativeName: 'বাংলা',
  },
  {
    code: 'bs',
    isoName: 'Bosnian',
    nativeName: 'bosanski jezik',
  },
  {
    code: 'bg',
    isoName: 'Bulgarian',
    nativeName: 'български език',
  },
  {
    code: 'ca',
    isoName: 'Catalan',
    nativeName: 'Català',
    translations: {
      'View the {{nativeName}} version': 'Veure la versió catalana',
    },
  },
  // {
  //   code: 'ceb',
  //   isoName: 'Cebuano'
  // },
  {
    code: 'zh',
    isoName: 'Chinese (Simplified)',
    nativeName: '中文',
    translations: {
      'View the {{nativeName}} version': '查看简体中文版本',
    },
  },
  {
    code: 'zh-TW',
    isoName: 'Chinese (Traditional)',
    nativeName: '中文',
  },
  {
    code: 'co',
    isoName: 'Corsican',
    nativeName: 'corsu',
  },
  {
    code: 'hr',
    isoName: 'Croatian',
    nativeName: 'Hrvatski',
  },
  {
    code: 'cs',
    isoName: 'Czech',
    nativeName: 'čeština',
    translations: {
      'View the {{nativeName}} version': 'Zobrazit českou verzi',
    },
  },
  {
    code: 'da',
    isoName: 'Danish',
    nativeName: 'dansk',
  },
  {
    code: 'nl',
    isoName: 'Dutch',
    nativeName: 'Nederlands',
    translations: {
      'View the {{nativeName}} version': 'De Nederlandse versie bekijken',
    },
  },
  {
    code: 'en',
    isoName: 'English',
    nativeName: 'English',
  },
  {
    code: 'eo',
    isoName: 'Esperanto',
    nativeName: 'Esperanto',
  },
  {
    code: 'et',
    isoName: 'Estonian',
    nativeName: 'eesti',
  },
  {
    code: 'fi',
    isoName: 'Finnish',
    nativeName: 'suomi',
  },
  {
    code: 'fr',
    isoName: 'French',
    nativeName: 'Français',
  },
  {
    code: 'fy',
    isoName: 'Frisian',
    nativeName: 'Frysk',
  },
  {
    code: 'gl',
    isoName: 'Galician',
    nativeName: 'galego',
  },
  {
    code: 'ka',
    isoName: 'Georgian',
    nativeName: 'ქართული',
  },
  {
    code: 'de',
    isoName: 'German',
    nativeName: 'Deutsch',
    translations: {
      'View the {{nativeName}} version': 'Die deutsche Version anzeigen',
    },
  },
  {
    code: 'el',
    isoName: 'Greek',
    nativeName: 'Ελληνικά',
  },
  {
    code: 'gu',
    isoName: 'Gujarati',
    nativeName: 'ગુજરાતી',
  },
  {
    code: 'ht',
    isoName: 'Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
  },
  {
    code: 'ha',
    isoName: 'Hausa',
    nativeName: 'هَوُسَ',
  },
  {
    code: 'haw',
    isoName: 'Hawaiian',
    nativeName: 'Ōlelo Hawaiʻi',
  },
  {
    code: 'he',
    isoName: 'Hebrew',
    nativeName: 'עברית',
  },
  {
    code: 'hi',
    isoName: 'Hindi',
    nativeName: 'हिन्दी',
  },
  {
    code: 'hmn',
    isoName: 'Hmong',
    nativeName: 'Hmoob',
  },
  {
    code: 'hu',
    isoName: 'Hungarian',
    nativeName: 'magyar',
    translations: {
      'View the {{nativeName}} version': 'Magyar verzió megtekintése',
    },
  },
  {
    code: 'is',
    isoName: 'Icelandic',
    nativeName: 'Íslenska',
  },
  {
    code: 'ig',
    isoName: 'Igbo',
    nativeName: 'Asụsụ Igbo',
  },
  {
    code: 'id',
    isoName: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
  },
  {
    code: 'ga',
    isoName: 'Irish',
    nativeName: 'Gaeilge',
  },
  {
    code: 'it',
    isoName: 'Italian',
    nativeName: 'Italiano',
    translations: {
      'View the {{nativeName}} version': 'Visualizza la versione italiana',
    },
  },
  {
    code: 'ja',
    isoName: 'Japanese',
    nativeName: '日本語',
  },
  {
    code: 'jv',
    isoName: 'Javanese',
    nativeName: 'basa Jawa',
    translations: {
      'View the {{nativeName}} version': 'Ikusi euskal bertsioa',
    },
  },
  {
    code: 'kn',
    isoName: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
  },
  {
    code: 'kk',
    isoName: 'Kazakh',
    nativeName: 'қазақ тілі',
  },
  {
    code: 'km',
    isoName: 'Khmer',
    nativeName: 'ខេមរភាសា',
  },
  {
    code: 'rw',
    isoName: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  {
    code: 'ko',
    isoName: 'Korean',
    nativeName: '한국어',
    translations: {
      'View the {{nativeName}} version': '한국어 버전 보기',
    },
  },
  {
    code: 'ku',
    isoName: 'Kurdish',
    nativeName: 'Kurdî',
  },
  {
    code: 'ky',
    isoName: 'Kyrgyz',
    nativeName: 'Кыргызча',
  },
  {
    code: 'lo',
    isoName: 'Lao',
    nativeName: 'ພາສາ',
  },
  {
    code: 'lv',
    isoName: 'Latvian',
    nativeName: 'latviešu valoda',
  },
  {
    code: 'lt',
    isoName: 'Lithuanian',
    nativeName: 'lietuvių kalba',
  },
  {
    code: 'lb',
    isoName: 'Luxembourgish',
    nativeName: 'Lëtzebuergesch',
  },
  {
    code: 'mk',
    isoName: 'Macedonian',
    nativeName: 'македонски јазик',
  },
  {
    code: 'mg',
    isoName: 'Malagasy',
    nativeName: 'fiteny malagasy',
  },
  {
    code: 'ms',
    isoName: 'Malay',
    nativeName: 'Bahasa Malaysia',
  },
  {
    code: 'ml',
    isoName: 'Malayalam',
    nativeName: 'മലയാളം',
  },
  {
    code: 'mt',
    isoName: 'Maltese',
    nativeName: 'Malti',
  },
  {
    code: 'mi',
    isoName: 'Maori',
    nativeName: 'te reo Māori',
  },
  {
    code: 'mr',
    isoName: 'Marathi',
    nativeName: 'मराठी',
  },
  {
    code: 'mn',
    isoName: 'Mongolian',
    nativeName: 'Монгол хэл',
  },
  {
    code: 'my',
    isoName: 'Myanmar (Burmese)',
    nativeName: 'ဗမာစာ',
  },
  {
    code: 'ne',
    isoName: 'Nepali',
    nativeName: 'नेपाली',
  },
  {
    code: 'no',
    isoName: 'Norwegian',
    nativeName: 'Norsk',
  },
  {
    code: 'ny',
    isoName: 'Nyanja (Chichewa)',
    nativeName: 'chiCheŵa',
  },
  {
    code: 'or',
    isoName: 'Odia (Oriya)',
    nativeName: 'ଓଡ଼ିଆ',
  },
  {
    code: 'ps',
    isoName: 'Pashto',
    nativeName: 'پښتو',
  },
  {
    code: 'fa',
    isoName: 'Persian',
    nativeName: 'فارسی',
  },
  {
    code: 'pl',
    isoName: 'Polish',
    nativeName: 'Polski',
    translations: {
      'View the {{nativeName}} version': 'Zobacz wersję polską',
    },
  },
  {
    code: 'pt',
    isoName: 'Portuguese',
    nativeName: 'Português',
    translations: {
      'View the {{nativeName}} version': 'Ver a versão portuguesa',
    },
  },
  {
    code: 'pa',
    isoName: 'Punjabi',
    nativeName: 'ਪੰਜਾਬੀ',
  },
  {
    code: 'ro',
    isoName: 'Romanian',
    nativeName: 'Română',
  },
  {
    code: 'ru',
    isoName: 'Russian',
    nativeName: 'Русский',
    translations: {
      'View the {{nativeName}} version': 'Просмотреть русскую версию',
    },
  },
  {
    code: 'sm',
    isoName: 'Samoan',
    nativeName: 'Samoa',
  },
  {
    code: 'gd',
    isoName: 'Scots Gaelic',
    nativeName: 'Gàidhlig',
  },
  {
    code: 'sr',
    isoName: 'Serbian',
    nativeName: 'српски језик',
    translations: {
      'View the {{nativeName}} version': 'Pogledajte srpsku verziju',
    },
  },
  {
    code: 'st',
    isoName: 'Sesotho',
    nativeName: 'Sesotho',
  },
  {
    code: 'sn',
    isoName: 'Shona',
    nativeName: 'chiShona',
  },
  {
    code: 'sd',
    isoName: 'Sindhi',
    nativeName: 'सिन्धी',
  },
  {
    code: 'si',
    isoName: 'Sinhala (Sinhalese)',
    nativeName: 'සිංහල',
  },
  {
    code: 'sk',
    isoName: 'Slovak',
    nativeName: 'slovenčina',
    translations: {
      'View the {{nativeName}} version': 'Zobraziť slovenskú verziu',
    },
  },
  {
    code: 'sl',
    isoName: 'Slovenian',
    nativeName: 'slovenščina',
  },
  {
    code: 'so',
    isoName: 'Somali',
    nativeName: 'Soomaaliga',
  },
  {
    code: 'es',
    isoName: 'Spanish',
    nativeName: 'Español',
    translations: {
      'View the {{nativeName}} version': 'Ver la versión en español',
    },
  },
  {
    code: 'su',
    isoName: 'Sundanese',
    nativeName: 'Basa Sunda',
  },
  {
    code: 'sw',
    isoName: 'Swahili',
    nativeName: 'Kiswahili',
  },
  {
    code: 'sv',
    isoName: 'Swedish',
    nativeName: 'Svenska',
    translations: {
      'View the {{nativeName}} version': 'Se den svenska versionen',
    },
  },
  {
    code: 'tl',
    isoName: 'Tagalog (Filipino)',
    nativeName: 'Wikang Tagalog',
    translations: {
      'View the {{nativeName}} version': 'Tingnan ang bersyon ng Tagalog',
    },
  },
  {
    code: 'tg',
    isoName: 'Tajik',
    nativeName: 'тоҷикӣ',
  },
  {
    code: 'ta',
    isoName: 'Tamil',
    nativeName: 'தமிழ்',
  },
  {
    code: 'tt',
    isoName: 'Tatar',
    nativeName: 'татар теле',
  },
  {
    code: 'te',
    isoName: 'Telugu',
    nativeName: 'తెలుగు',
  },
  {
    code: 'th',
    isoName: 'Thai',
    nativeName: 'ไทย',
  },
  {
    code: 'tr',
    isoName: 'Turkish',
    nativeName: 'Türkçe',
  },
  {
    code: 'tk',
    isoName: 'Turkmen',
    nativeName: 'Türkmen',
  },
  {
    code: 'uk',
    isoName: 'Ukrainian',
    nativeName: 'Українська',
  },
  {
    code: 'ur',
    isoName: 'Urdu',
    nativeName: 'اردو',
  },
  {
    code: 'ug',
    isoName: 'Uyghur',
    nativeName: 'ئۇيغۇرچە‎',
  },
  {
    code: 'uz',
    isoName: 'Uzbek',
    nativeName: 'Ўзбек',
  },
  {
    code: 'vi',
    isoName: 'Vietnamese',
    nativeName: 'Tiếng Việt',
  },
  {
    code: 'cy',
    isoName: 'Welsh',
    nativeName: 'Cymraeg',
  },
  {
    code: 'xh',
    isoName: 'Xhosa',
    nativeName: 'isiXhosa',
  },
  {
    code: 'yi',
    isoName: 'Yiddish',
    nativeName: 'ייִדיש',
  },
  {
    code: 'yo',
    isoName: 'Yoruba',
    nativeName: 'Yorùbá',
  },
  {
    code: 'zu',
    isoName: 'Zulu',
    nativeName: 'isiZulu',
  },
]

export const sourceLangaugeCode = 'en'

export const languagesByCode = new Map<string, Language>()
for (const language of languages) {
  languagesByCode.set(language.code, language)
}

export const findLanguageByCode = (code: LanguageCode | null) =>
  code && languagesByCode.get(code)

export const useFilteredLanguages = (search?: string) =>
  useMemo(() => {
    if (!search) return languages
    const text = search.toLowerCase()
    return languages.filter((l) => l.isoName.toLowerCase().includes(text))
  }, [search])
