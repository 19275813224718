import classnames from 'classnames'
import React from 'react'

export interface SvgIconProps {
  className?: string
  iconName: string
  size: number
  [key: string]: any
}

const SvgIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  ({ className, iconName, size, ...props }, ref) => (
    <svg
      height={size}
      width={size}
      {...props}
      className={classnames(`icon icon-${iconName}`, className)}
      ref={ref}
    />
  ),
)

export type AnnotationIconProps = Omit<SvgIconProps, 'iconName'> & {
  hasAnnotation?: boolean
}

export const AnnotationIcon = React.forwardRef<
  SVGSVGElement,
  AnnotationIconProps
>(({ className, hasAnnotation, ...props }, ref) => (
  <SvgIcon
    {...props}
    className={classnames(className, {
      '-annotation': hasAnnotation === true,
      '-dirty': hasAnnotation === false,
    })}
    iconName='annotation'
    ref={ref}
    viewBox='0 0 16 16'
  >
    <path
      className='-outline'
      d='M16,11.2857143 C16,11.7619046 15.8333332,12.1666668 15.5,12.5 L12.5,15.5 C12.1666668,15.8333332 11.7619046,16 11.2857143,16 L1.71428571,16 C1.23809542,16 0.833333152,15.8333332 0.5,15.5 C0.166666848,15.1666668 0,14.7619046 0,14.2857143 L0,1.71428571 C0,1.23809542 0.166666848,0.833333152 0.5,0.5 C0.833333152,0.166666848 1.23809542,0 1.71428571,0 L14.2857143,0 C14.7619046,0 15.1666668,0.166666848 15.5,0.5 C15.8333332,0.833333152 16,1.23809542 16,1.71428571 L16,11.2857143 Z M11.4285714,14.1428571 L14.1428571,11.4285714 L11.4285714,11.4285714 L11.4285714,14.1428571 Z M14.2857143,1.71428571 L1.71428571,1.71428571 L1.71428571,14.2857143 L9.71428571,14.2857143 L9.71428571,10.5714286 C9.71428571,10.3333332 9.79761887,10.1309526 9.96428571,9.96428571 C10.1309526,9.79761887 10.3333332,9.71428571 10.5714286,9.71428571 L14.2857143,9.71428571 L14.2857143,1.71428571 Z'
    />
    <path
      className='-text'
      d='M4.5,5 L11.5,5 C11.7761424,5 12,5.22385763 12,5.5 L12,6.5 C12,6.77614237 11.7761424,7 11.5,7 L4.5,7 C4.22385763,7 4,6.77614237 4,6.5 L4,5.5 C4,5.22385763 4.22385763,5 4.5,5 Z M4.5,9 L7.5,9 C7.77614237,9 8,9.22385763 8,9.5 L8,10.5 C8,10.7761424 7.77614237,11 7.5,11 L4.5,11 C4.22385763,11 4,10.7761424 4,10.5 L4,9.5 C4,9.22385763 4.22385763,9 4.5,9 Z'
    />
  </SvgIcon>
))

export type ChangeIconProps = Omit<SvgIconProps, 'iconName'>

export const ChangeIcon = (props: ChangeIconProps) => (
  <SvgIcon {...props} iconName='change' viewBox='0 0 14 14'>
    <path d='M14,7.19563736 C12.8476523,7.67280402 11.9292895,8.10224758 11.244884,8.48398091 C10.5604786,8.86571425 10.027287,9.24426079 9.64529332,9.6196319 C9.26966615,9.99500301 8.89086139,10.5230595 8.50886767,11.2038173 C8.12687394,11.8845751 7.69077098,12.8166267 7.2005457,14 L6.80900409,14 C6.31241225,12.8166267 5.87312606,11.8845751 5.49113233,11.2038173 C5.10913861,10.5230595 4.73351708,9.99500301 4.36425648,9.6196319 C3.98226276,9.24426079 3.44907118,8.86571425 2.76466576,8.48398091 C2.08026033,8.10224758 1.1587143,7.67280402 0,7.19563736 L0,6.80436264 C1.16508086,6.32719598 2.08981013,5.89775242 2.77421555,5.51601909 C3.45862098,5.13428575 3.98862932,4.75573921 4.36425648,4.3803681 C4.73351708,4.00499699 5.10913861,3.47694046 5.49113233,2.79618269 C5.87312606,2.11542491 6.31241225,1.18337333 6.80900409,0 L7.2005457,0 C7.69077098,1.18337333 8.12687394,2.11542491 8.50886767,2.79618269 C8.89086139,3.47694046 9.26966615,4.00499699 9.64529332,4.3803681 C10.0145539,4.75573921 10.541379,5.13428575 11.2257844,5.51601909 C11.9101899,5.89775242 12.8349191,6.32719598 14,6.80436264 L14,7.19563736 Z' />
  </SvgIcon>
)
